import styled from "styled-components";

export const Container = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding-bottom: 20px;
`
export const Steps = styled.div`
  display: flex;
  gap: 8px;
`
export const IconBtn = styled.button`
  background-color: white;
  height: 46px;
  width: 46px;
  border: none;
  cursor: pointer;
`