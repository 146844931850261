export function getAllQueryParamsString(searchParams) {
    let paramsString = '';
    searchParams.forEach((value, key) => {
        paramsString += `${key}=${value}&`;
    });
    // Remove the last '&' character
    paramsString = paramsString.slice(0, -1);
    return paramsString;
}
export function encodeQueryData(data) {
    const ret = [];
    for (let d in data)
        ret.push(encodeURIComponent(d) + "=" + encodeURIComponent(data[d]));
    return ret.join("&");
}

export function getQueryParams() {
    const queryString = window.location.search;
    const queryParams = new URLSearchParams(queryString);
    const params = {};
    for (let [key, value] of queryParams.entries()) {
        params[key] = value;
    }
    return params;
}
