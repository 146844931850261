import * as React from "react";
const CameraIcon = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={26}
    height={23}
    fill="none"
    {...props}
  >
    <mask id="a" fill="#fff">
      <path
        fillRule="evenodd"
        d="M2.166 2.738C.972 2.738 0 3.902 0 5.135v15.633C0 22 .97 23 2.166 23h21.668C25.027 23 26 22 26 20.768V5.135c0-1.232-.97-2.397-2.166-2.397h-3.25c-.599 0-1.339-.308-1.406-.506C18.758 1 17.447 0 16.25 0h-6.5C8.554 0 7.243 1 6.822 2.232c-.126.37-.805.506-1.405.506h-3.25ZM13 16a4 4 0 1 0 0-8 4 4 0 0 0 0 8Z"
        clipRule="evenodd"
      />
    </mask>
    <path
      fill="#fff"
      d="m19.178 2.232-1.893.646 1.893-.646Zm-12.356 0L4.93 1.587l1.893.645ZM2.166.738C-.363.738-2 3.043-2 5.135h4a.571.571 0 0 1 .136-.339c.085-.096.108-.058.03-.058v-4ZM-2 5.135v15.633h4V5.135h-4Zm0 15.633C-2 23.048-.191 25 2.166 25v-4c-.026 0-.058-.008-.096-.047a.26.26 0 0 1-.07-.185h-4ZM2.166 25h21.668v-4H2.166v4Zm21.668 0C26.188 25 28 23.05 28 20.768h-4a.26.26 0 0 1-.07.185c-.038.04-.071.047-.096.047v4ZM28 20.768V5.135h-4v15.633h4Zm0-15.633c0-2.09-1.634-4.397-4.166-4.397v4c-.077 0-.054-.037.03.058a.568.568 0 0 1 .136.34h4ZM23.834.738h-3.25v4h3.25v-4Zm-3.25 0c.014 0-.006.001-.058-.01a.932.932 0 0 1-.135-.039c-.046-.017-.03-.016.032.023.03.02.112.074.211.171a1.85 1.85 0 0 1 .437.703l-3.786 1.292c.151.443.413.728.547.86.155.152.31.263.428.339.238.153.49.268.712.352a4.673 4.673 0 0 0 1.611.31v-4Zm.487.849C20.715.543 20.016-.328 19.218-.937 18.427-1.54 17.385-2 16.25-2v4c.062 0 .274.04.542.244.261.2.428.445.493.634l3.786-1.291ZM16.25-2H9.75v4h6.5v-4ZM9.75-2c-1.135 0-2.176.46-2.967 1.063-.798.609-1.498 1.48-1.854 2.524l3.786 1.29c.064-.188.231-.433.493-.633.269-.205.48-.244.542-.244v-4ZM4.93 1.587A1.52 1.52 0 0 1 5.471.84a1.065 1.065 0 0 1 .237-.127c.008-.003-.018.005-.082.013-.06.008-.133.012-.21.012v4a5.1 5.1 0 0 0 1.469-.202c.381-.118 1.436-.505 1.83-1.658L4.928 1.587Zm.487-.849h-3.25v4h3.25v-4ZM13 18a6 6 0 0 0 6-6h-4a2 2 0 0 1-2 2v4Zm6-6a6 6 0 0 0-6-6v4a2 2 0 0 1 2 2h4Zm-6-6a6 6 0 0 0-6 6h4a2 2 0 0 1 2-2V6Zm-6 6a6 6 0 0 0 6 6v-4a2 2 0 0 1-2-2H7Z"
      mask="url(#a)"
    />
  </svg>
);
export default CameraIcon;
