import styled from "styled-components";

export const Container = styled.div`
  padding: ${(props) => (props.alternative ? "12px" : "16px")};
  background-color: ${(props) => (props.alternative ? "white" : "#f2f2f7")};
  border-radius: 16px;
  display: flex;
  flex-direction: row;
  gap: 8px;
`;

export const Title = styled.p`
  font-size: ${(props) => (props.alternative ? "12px" : "14px")};
  line-height: ${(props) => (props.alternative ? "16px" : "20px")};
  font-weight: 400;
  color: #5D7380;
`;
