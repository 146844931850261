import * as React from 'react';

const Privacy = () => (
  <svg width={26} height={26} fill='none' xmlns='http://www.w3.org/2000/svg'>
    <path
      d='M12.896 25.42c.207 0 .524-.081.821-.243 6.74-3.68 9.076-5.363 9.076-9.638v-8.86c0-1.388-.538-1.878-1.7-2.374-1.303-.542-5.694-2.087-6.972-2.523a4.181 4.181 0 0 0-1.225-.205c-.393 0-.836.102-1.215.205-1.277.365-5.678 1.99-6.98 2.523C3.548 4.791 3 5.291 3 6.68v8.859c0 4.275 2.345 5.949 9.075 9.638.307.162.615.242.822.242Zm0-2.461c-.168 0-.334-.062-.676-.269-5.357-3.266-7.02-4.145-7.02-7.589V7.053c0-.413.08-.578.412-.709 1.735-.688 5.103-1.812 6.672-2.43.264-.098.446-.132.612-.132.167 0 .347.044.613.133 1.569.617 4.918 1.808 6.682 2.43.32.12.401.295.401.708v8.048c0 3.463-1.721 4.393-7.02 7.59-.331.204-.507.268-.675.268Z'
      fill='#29C565'
    />
    <path
      d='M11.638 18.443c.42 0 .787-.209 1.038-.584l5.24-8.199c.157-.241.291-.516.291-.79 0-.574-.52-.967-1.063-.967-.35 0-.671.195-.92.59L11.6 15.928 9.42 13.15c-.288-.371-.562-.493-.906-.493-.575 0-1.03.456-1.03 1.04 0 .274.107.538.3.792l2.774 3.377c.312.401.655.577 1.08.577Z'
      fill='#29C565'
    />
  </svg>
);

export default Privacy;
