import React from 'react';

const VideoPlayer = () => {
  const windowInnerWidth = window.innerWidth - 32;

  return (
    <video
      width={`${windowInnerWidth}`}
      height={`${windowInnerWidth * (5 / 4)}`}
      playsInline
      // controls
      autoPlay
      muted
    >
      <source src='https://storage.yandexcloud.net/e-size-content/E-Size_1080_350.mp4' type='video/mp4' />
    </video>
  );
};

export default VideoPlayer;
