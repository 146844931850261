import styled from "styled-components";

export const Page = styled.div`
  display: block;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  min-height: 100vh;
  background-color: rgba(0, 0, 0, 0.7);
`;

export const Container = styled.div`
  padding: 5mm;
  max-width: 600px;
  margin-left: auto;
  margin-right: auto;
`

export const MainCard = styled.div`
  width: 100%;
  background-color: #f2f2f7;
  border-radius: 20px;
  padding: 20px;
  margin-bottom: 32px;
`;

export const Step = styled.h1`
  margin-bottom: 8px;
`;

export const Message = styled.p`
  margin-bottom: 16px;
  font-weight: 400;
  font-size: 15px;
  line-height: 20px;
`;

export const ColumnsWrapper = styled.div`
  width: 100%;
  height: 100%;
  display: block;
  align-items: stretch;
  justify-content: space-between;
  gap: 13px;
`;

export const QrWrapper = styled.div`
  border-radius: 14px;
  background-color: white;
  display: flex;
  justify-content: center;
  align-items: center;
  align-self: flex-end;
  width: 100%;
`;

export const FirstStep = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  width: 100%;
  margin-bottom: 20px;
`;

export const SecondStep = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  width: 100%;
  margin-bottom: 50px;
`;
export const ThirdStep = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  width: 100%;
`;

export const ImgsAndCardsWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 13px;
`;

export const Img = styled.img`
  max-width: 100%;
  max-height: 100%;
  width: 100%;
`;

export const RecommendsWrapper = styled.div`
  margin-top: 24px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 20px;
`;

export const RecommendsMessage = styled.p`
  font-weight: 400;
  font-size: 12px;
  line-height: 16px;
  color: #5d7380;
`;

export const AdvicesWrapper = styled.div`
  width: 100%;
  padding-left: 20px;
`;

export const H1 = styled.h1`
  font-size: 30px;
  line-height: 48px;
  color: white;
  margin-bottom: 24px;
  margin-left: -20px;
`;

export const Advice = styled.p`
  font-size: 20px;
  line-height: 125%;
  color: white;
  margin-bottom: 20px;
`;
