export const accelerometerDataToAngle = (
    Yg,
    Xg,
    isReversedAcc,
    orientationAngle
) => {
    if (orientationAngle === 90 || orientationAngle === 270) {
        return 0;
    }
    if (isReversedAcc) {
        if (Yg < -7) return 180;
        if (Xg < -5) return 90;
        if (Xg > 5) return -90;
    } else {
        if (Yg > 7) return 180;
        if (Xg < -5) return -90;
        if (Xg > 5) return 90;
    }

    return 0; // Return a default angle if no conditions are met
};
