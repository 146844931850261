import React from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { Alert, AlertBtn, Container, Message, TextBlock, Title } from './styles';

const TimeoutError = () => {
  const navigate = useNavigate();
  const { t } = useTranslation()

  return (
    <Container>
      <Alert>
        <TextBlock>
          <Title>{t('error2_dialog_title')}</Title>
          <Message>{t('error2_dialog_text')}</Message>
        </TextBlock>
        <AlertBtn onClick={() => navigate('/scanning')}>{t('error2_dialog_button')}</AlertBtn>
      </Alert>
    </Container>
  );
};

export default TimeoutError;
