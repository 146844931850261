import React from 'react';
import { Container, Title } from './styles';

const Card = ({ icon, text, alternative = false }) => {
  return (
    <Container alternative={alternative}>
      <div>{icon}</div>
      <Title alternative={alternative}>{text}</Title>
    </Container>
  );
};

export default Card;
