import React from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { Alert, AlertBtn, Container, Message, TextBlock, Title } from './styles';

const BackendError = () => {
  const { t } = useTranslation();

  const navigate = useNavigate();

  return (
    <Container>
      <Alert>
        <TextBlock>
          <Title>{t('error1_dialog_title')}</Title>
          <Message>{t('error1_dialog_text')}</Message>
        </TextBlock>
        <AlertBtn onClick={() => navigate('/scanning')}>{t('error1_dialog_button')}</AlertBtn>
      </Alert>
    </Container>
  );
};

export default BackendError;
