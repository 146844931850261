import React from 'react';
import { Container, IconWrapper, Title } from './styles';

function ConditionCard({ icon, text }) {
  return (
    <Container>
      <IconWrapper>{icon}</IconWrapper>
      <Title>{text}</Title>
    </Container>
  );
}

export default ConditionCard;
