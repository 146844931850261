import PinnedButton from "../../components/Button/PinnedButton";
import React, { useEffect } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import VideoPlayer from "../../components/VideoPlayer/VideoPlayer";
import { PageContainer } from "../../styles/styles";
import { Content } from "./styles";
import { useTranslation } from "react-i18next";
import { useContext } from "react";
import AppContext from "../../utils/Context/context";
import axios from "axios";
import { getAllQueryParamsString } from "../../helpers";

function VideoInstruction() {
  const { setGuestToken, setEsizeid, serverEndpoint } = useContext(AppContext);
  const [searchParams] = useSearchParams();
  const queryParamsString = getAllQueryParamsString(searchParams);

  useEffect(() => {
    const options = {
      method: "POST",
      url: `${serverEndpoint}guest`,
      headers: {
        accept: "application/json",
        "Content-Type": "application/x-www-form-urlencoded",
      },
    };
    axios(options)
      .then((response) => {
        console.log("guest response:", response.data);
        console.log("guestToken:", response.data.data.token);
        setGuestToken(response.data.data.token);
        setEsizeid(response.data.data.esizeid);
      })
      .catch((error) => {
        console.error(error);
      });
  }, [serverEndpoint, setEsizeid, setGuestToken]);

  const { t } = useTranslation();
  const navigate = useNavigate();
  const skipHandler = () => {
    navigate(`/scanning?${queryParamsString}`);
  };

  return (
    <PageContainer>
      <Content>
        <h1>{t("tutorial4_title")}</h1>
        <VideoPlayer />
      </Content>
      <PinnedButton onClick={skipHandler} type="Secondary">
        {t("tutorial4_button_skip")}
      </PinnedButton>
    </PageContainer>
  );
}

export default VideoInstruction;
