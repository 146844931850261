import React from 'react';
import { PageContainer } from '../../styles/styles';
import { Content } from './styles';
import { ReactComponent as Icon1 } from '../../assets/icons/1.svg';
import { ReactComponent as Icon2 } from '../../assets/icons/2.svg';
import { ReactComponent as Icon3 } from '../../assets/icons/3.svg';
import { ReactComponent as Icon4 } from '../../assets/icons/4.svg';
import { ReactComponent as Icon5 } from '../../assets/icons/5.svg';
import { ReactComponent as Icon6 } from '../../assets/icons/6.svg';
import ConditionCard from '../../components/ConditionCard/ConditionCard';
import PinnedButton from '../../components/Button/PinnedButton';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

const Error = () => {
  const { t } = useTranslation();

  const errors = [
    { icon: <Icon1 />, text: t('scan_final_step_term_1') },
    { icon: <Icon2 />, text: t('scan_final_step_term_2') },
    { icon: <Icon3 />, text: t('scan_final_step_term_3') },
    { icon: <Icon4 />, text: t('scan_final_step_term_4') },
    { icon: <Icon5 />, text: t('scan_final_step_term_5') },
    { icon: <Icon6 />, text: t('scan_final_step_term_6') },
  ];

  const navigate = useNavigate();

  return (
    <PageContainer>
      <Content>
        <h1>{t('scan_final_step_title_error')}</h1>
        <p>{t('scan_final_step_message_error')}</p>
        {errors.map((e) => (
          <ConditionCard icon={e.icon} text={e.text} key={e.text} />
        ))}
      </Content>
      <PinnedButton onClick={() => navigate('/scanning')}>{t('scan_final_step_button_repeat')}</PinnedButton>
    </PageContainer>
  );
};

export default Error;
