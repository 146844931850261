import styled from "styled-components";

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  //padding-bottom: 80px;
`;

export const Content = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;
`;

export const CameraAndImgWrapper = styled.div`
  // Медиазапрос для портретной ориентации
  @media (orientation: portrait) {
    height: calc(min(100vh, 100vw) - 32px);
    width: calc(min(100vh, 100vw) - 32px);
  }

  // Медиазапрос для ландшафтной ориентации
  @media (orientation: landscape) {
    height: calc(min(100vh, 100vw) - 96px);
    width: calc(min(100vh, 100vw) - 96px);
  }

  align-self: center;
  border-radius: 14px;
  overflow: hidden;
`;

export const HorizontalContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
`;

export const HeaderStepContainer = styled.div`
  z-index: 10;
  width: calc((100vw - (100vh - 60px)) / 2);
  max-width: 202px;
  position: absolute;
  display: flex;
  flex-wrap: wrap;
  //align-items: center;
  justify-content: space-between;
  padding-bottom: 20px;
`;
export const Steps = styled.div`
  display: flex;
  gap: 8px;
`;
export const IconBtn = styled.button`
  background-color: white;
  height: 46px;
  width: 46px;
  border: none;
  cursor: pointer;
`;
