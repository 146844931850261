import React, { Suspense } from "react";
import ReactDOM from "react-dom/client";
import App from "./App";
import { createGlobalStyle } from "styled-components";

import "./i18n";

const Global = createGlobalStyle`
  @import url('https://fonts.googleapis.com/css2?family=Open+Sans&display=swap');
  *{
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family:  "Inter", system-ui;
}
h1 {
  font-size: 32px;
  font-weight: 500;
  line-height: 40px;
  letter-spacing: -0.24px;
  color: #1D1D1B;
}
p {
  font-size: 16px;
  font-weight: 400;
  line-height: 24px;
  //letter-spacing: 0.5px;
  color: #1D1D1B;
}
video {
    border-radius: 14px;
}
li {
  color: white
}

`;

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <>
    <Global />
    <Suspense fallback={<div>Loading...</div>}>
      <App />
    </Suspense>
  </>
);
