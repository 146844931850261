import React from "react";
import { RoundButtonWrapper } from "./styles";
import CameraIcon from "../../assets/icons/CameraIcon";

const RoundButton = ({ ...props }) => {
  return (
    <RoundButtonWrapper {...props}>
      <CameraIcon />
    </RoundButtonWrapper>
  );
};

export default RoundButton;
