import React from 'react';
import styled from 'styled-components';

const SwitchWrapper = styled.div`
  display: flex;
  align-items: center;
  margin-top: 10px;
  margin-bottom: 30px;
`;

const Label = styled.label`
  font-size: 14px;
  margin-right: 8px;
`;

const SwitchButton = styled.button`
  background-color: ${({ value }) => (value === 'male' ? '#2683EA' : value ==='female' ? '#FF75AF': '#ffaa18')};
  color: white;
  border: none;
  border-radius: 16px;
  padding: 4px 0;
  width: 70px;
  cursor: pointer;
  transition: background-color 0.2s ease-in-out;
`;

const GenderSwitch = ({ setValue, value }) => {
// male female kid
  const handleSwitch = () => {
    if (value === 'male') {
      setValue('female')
    } else if (value === 'female') {
      setValue('kid')
    } else {
      setValue('male')
    }
  };

  return (
    <SwitchWrapper>
      <Label>gender:</Label>
      <SwitchButton value={value} onClick={handleSwitch}>
        <span>{value}</span>
      </SwitchButton>
    </SwitchWrapper>
  );
};


export default GenderSwitch;
