import React, { useContext, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate, useSearchParams } from "react-router-dom";
import AppContext from "../../utils/Context/context";
import { Alert, AlertBtn, Container, TextBlock, Title } from "./styles";
import { getAllQueryParamsString } from "../../helpers";

const ChangeLanguage = () => {
  const navigate = useNavigate();
  const { i18n } = useTranslation();
  const [searchParams] = useSearchParams();
  const queryParamsString = getAllQueryParamsString(searchParams);
  const { setCode } = useContext(AppContext);

  useEffect(() => {
    const newCode = searchParams.get("code");
    setCode(newCode || "");

    const lang = window.navigator.language;
    const langTrimmed = lang.substring(0, 2);
    console.log("window.navigator.language:", lang);

    const isMobile =
      /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(
        navigator.userAgent
      );
    if (
      langTrimmed === "ru" ||
      langTrimmed === "en" ||
      langTrimmed === "zh" ||
      langTrimmed === "ar"
    ) {
      i18n.changeLanguage(langTrimmed);
      navigate(`/videoinstruction?${queryParamsString}`);
    }
    if (!isMobile) {
      navigate(`/qr?${queryParamsString}`);
    }
  }, [i18n, navigate, queryParamsString, searchParams, setCode]);

  const handleLanguageChange = (lng) => {
    navigate("/videoinstruction");
    i18n.changeLanguage(lng);
  };

  return (
    <Container>
      <Alert>
        <TextBlock>
          <Title>Choose your language</Title>
        </TextBlock>
        <AlertBtn onClick={() => handleLanguageChange("ru")}>Русский</AlertBtn>
        <AlertBtn onClick={() => handleLanguageChange("en")}>English</AlertBtn>
        <AlertBtn onClick={() => handleLanguageChange("ar")}>العربية</AlertBtn>
        <AlertBtn onClick={() => handleLanguageChange("zh")}>中文</AlertBtn>
      </Alert>
    </Container>
  );
};

export default ChangeLanguage;
