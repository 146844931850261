export function makeBlob(dataURL) {
  const [, base64] = dataURL.split(";base64,");
  const contentType = dataURL.slice(5, dataURL.indexOf(";"));
  const raw = atob(base64);
  const uInt8Array = new Uint8Array(
    Array.from(raw).map((char) => char.charCodeAt(0))
  );

  return new Blob([uInt8Array], { type: contentType });
}

export function rotateCallback(srcBase64, degrees = 90, callback) {
  const canvas = document.createElement("canvas");
  const ctx = canvas.getContext("2d");
  const image = new Image();

  image.onload = function () {
    canvas.width = degrees % 180 === 0 ? image.width : image.height;
    canvas.height = degrees % 180 === 0 ? image.height : image.width;

    ctx.translate(canvas.width / 2, canvas.height / 2);
    ctx.rotate((degrees * Math.PI) / 180);
    ctx.drawImage(image, image.width / -2, image.height / -2);

    callback(canvas.toDataURL());
  };

  image.src = srcBase64;
}
