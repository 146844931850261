import React from 'react';
import { useTranslation } from 'react-i18next';
import PinnedButton from '../../../components/Button/PinnedButton';
import Loader from '../../../components/Loader/Loader';
import { Container, Title } from './styles';

const Loading = ({cancellation}) => {
  const { t } = useTranslation();
  return (
    <Container>
      <Title>{t('scan_final_step_title')}</Title>
      <p>{t('scan_final_step_message')}</p>
      <Loader />
      <PinnedButton onClick={cancellation} type='Red'>
        {t('scan_final_step_button_cancel')}
      </PinnedButton>
    </Container>
  );
};

export default Loading;
