import * as React from 'react';

const ExclamationMark = () => (
  <svg width={26} height={26} fill='none' xmlns='http://www.w3.org/2000/svg'>
    <path
      d='M12.995 24C19.024 24 24 19.021 24 13S19.013 2 12.984 2C6.966 2 2 6.979 2 13s4.976 11 10.995 11Zm0-2.192c-4.88 0-8.783-3.925-8.783-8.808a8.754 8.754 0 0 1 8.772-8.798A8.79 8.79 0 0 1 21.8 13a8.776 8.776 0 0 1-8.804 8.808Zm-.01-7.042c.584 0 .924-.33.935-.947l.16-5.117c.02-.628-.447-1.085-1.107-1.085-.659 0-1.116.447-1.095 1.074l.15 5.128c.02.606.36.947.956.947Zm0 3.532c.69 0 1.254-.5 1.254-1.181 0-.67-.553-1.17-1.255-1.17-.691 0-1.255.5-1.255 1.17 0 .67.575 1.18 1.255 1.18Z'
      fill='#FF3824'
    />
  </svg>
);

export default ExclamationMark;
