import React, { useContext, useEffect } from "react";
import CardSizes from "../../components/CardSizes/CardSizes";
import { Container, ContentWrapper, IconBtn, Title } from "./styles";
import Button from "../../components/Button/Button";
import { ReactComponent as ReScanIcon } from "../../assets/icons/reScan.svg";
import { ReactComponent as Logo } from "../../assets/icons/logoWithName.svg";
import { useNavigate } from "react-router-dom";
import AppContext from "../../utils/Context/context";
import { useTranslation } from "react-i18next";
import axios from "axios";

const Results = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { code, esizeid, results, guestToken, serverEndpoint } =
    useContext(AppContext);
  console.log(results);

  const shopSend = async () => {
    const options = {
      method: "post",
      url: `${serverEndpoint}shop/send?esizeid=${esizeid}&code=${code}`,
      headers: {
        authorization: "Bearer " + guestToken,
        "Content-Type": "multipart/form-data",
      },
    };

    await axios(options)
      .then((response) => {
        console.log("response.data:", response.data);
        navigate("/final");
      })
      .catch((error) => {
        console.log("backend error:", error);
        alert("Ошибка передачи данных в магазин");
      });
  };

  useEffect(() => {
    if (code === "") {
      setTimeout(() => {
        alert(t("results_alert"));
      }, 3000);
    }
  }, [code, t]);

  function redirectToAppStore() {
    const userAgent = navigator.userAgent.toLowerCase();
    const isIOS = /iphone|ipad|ipod/.test(userAgent);
    const isAndroid = /android/.test(userAgent);

    if (isIOS) {
      window.location.href = "https://apps.apple.com/app/e-size/id6445945414";
    } else if (isAndroid) {
      window.location.href =
        "https://play.google.com/store/apps/details?id=com.esize.app";
    } else {
      window.location.href =
        "https://play.google.com/store/apps/details?id=com.esize.app";
    }
  }

  return (
    <Container>
      <Logo />
      <ContentWrapper>
        <Title>{t("main_size_title")}</Title>
        <CardSizes unit="см" length={results.length} width={results.width} />
        <Button onClick={redirectToAppStore} type="Secondary">
          {t("download_app_title")}
        </Button>
        {code !== "" && (
          <Button onClick={shopSend}>{t("main_transfer_shop_button")}</Button>
        )}
        <IconBtn onClick={() => navigate("/scanning")}>
          <ReScanIcon />
        </IconBtn>
      </ContentWrapper>
    </Container>
  );
};

export default Results;
