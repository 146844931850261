import * as React from 'react';
import { Container } from './styles';

const Loader = () => (
  <Container>
    <svg width={86} height={86} fill='none' xmlns='http://www.w3.org/2000/svg'>
      <g clipPath='url(#a)' fill='#2683EA'>
        <path d='M16.958 74.233a9.171 9.171 0 1 0 0-18.342 9.171 9.171 0 0 0 0 18.342Z' fillOpacity={0.7} />
        <path d='M75.964 66.1a7.96 7.96 0 1 0 0-15.919 7.96 7.96 0 0 0 0 15.92Z' fillOpacity={0.4} />
        <path d='M68.696 25.956a6.575 6.575 0 1 0 0-13.151 6.575 6.575 0 0 0 0 13.15Z' fillOpacity={0.2} />
        <path
          d='M9.733 51.847c5.375 0 9.732-4.251 9.732-9.495 0-5.244-4.357-9.495-9.732-9.495-5.376 0-9.733 4.25-9.733 9.495 0 5.244 4.357 9.495 9.733 9.495Z'
          fillOpacity={0.8}
        />
        <path
          d='M37.691 86c4.887 0 8.848-3.86 8.848-8.622 0-4.761-3.961-8.621-8.848-8.621-4.886 0-8.847 3.86-8.847 8.621 0 4.762 3.96 8.622 8.847 8.622Z'
          fillOpacity={0.6}
        />
        <path
          d='M60.43 82.223c4.642 0 8.405-3.665 8.405-8.185 0-4.52-3.763-8.184-8.405-8.184-4.642 0-8.405 3.664-8.405 8.184s3.763 8.185 8.405 8.185Z'
          fillOpacity={0.5}
        />
        <path
          d='M20.261 29.796c5.62 0 10.175-4.447 10.175-9.932 0-5.486-4.555-9.932-10.175-9.932-5.619 0-10.175 4.447-10.175 9.932s4.556 9.932 10.175 9.932Z'
          fillOpacity={0.9}
        />
        <path
          d='M78.48 44.84c4.153 0 7.52-3.305 7.52-7.381 0-4.077-3.367-7.381-7.52-7.381-4.154 0-7.521 3.304-7.521 7.38 0 4.077 3.367 7.382 7.52 7.382Z'
          fillOpacity={0.3}
        />
        <path d='M45.509 21.457c5.925 0 10.728-4.803 10.728-10.729C56.237 4.803 51.434 0 45.51 0S34.781 4.803 34.781 10.728c0 5.926 4.803 10.729 10.728 10.729Z' />
      </g>
      <defs>
        <clipPath id='a'>
          <path fill='#fff' d='M0 0h86v86H0z' />
        </clipPath>
      </defs>
    </svg>
  </Container>
);

export default Loader;
