import React from 'react';
import { Container, IconBtn, Steps } from './styles';
import InfoIcon from '../../assets/icons/Info';
import { useTranslation } from 'react-i18next';

const StepHeader = ({ step }) => {
  const { t } = useTranslation();

  const handleInfoClick = () => {
    alert(t('dialog_personal_data_message'));
  };

  return (
    <Container>
      <Steps>
        <h1>{t(`scan${step}_title`)}</h1>
        <h1 style={{ fontSize: 28, color: '#5D7380', fontWeight: 400 }}>{t('scan1_title_suffix')}</h1>
      </Steps>
      <IconBtn onClick={handleInfoClick}>
        <InfoIcon />
      </IconBtn>
    </Container>
  );
};

export default StepHeader;
