import styled from "styled-components";

export const AppWrapper = styled.div`
  @media (orientation: portrait) {
    min-height: 100vh;
  }
`;

export const PageContainer = styled.div`
  padding: 0 16px 96px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
`;
