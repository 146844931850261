import React from "react";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import DesktopModal from "./DesktopModal/DesktopModal";
import Error from "./Error/Error";
import BackendError from "./ErrorWithAlert/BackendError";
import TimeoutError from "./ErrorWithAlert/TimeoutError";
import Results from "./Results/Results";
import Scanning from "./Scanning/Scanning";
import VideoInstruction from "./Videoinstruction/VideoInstruction";
import ChangeLanguage from "./ChangeLanguage/ChangeLanguage";
import MockPage from "./MockPage/MockPage";
import Final from "./Final/Final";

const AppRouter = () => {
  return (
    <BrowserRouter>
      <Routes>
        <Route path="/" element={<ChangeLanguage />} />
        <Route path="/videoinstruction" element={<VideoInstruction />} />
        <Route path="/scanning" element={<Scanning />} />
        <Route path="/error1" element={<BackendError />} />
        <Route path="/error2" element={<Error />} />
        <Route path="/errorTimeout" element={<TimeoutError />} />
        <Route path="/results" element={<Results />} />
        <Route path="/qr" element={<DesktopModal />} />
        <Route path="/mockPage" element={<MockPage />} />
        <Route path="/final" element={<Final />} />
      </Routes>
    </BrowserRouter>
  );
};

export default AppRouter;
