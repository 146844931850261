import React, {useContext, useEffect, useState} from "react";
import {
  Page,
  FirstStep,
  SecondStep,
  ThirdStep,
  MainCard,
  ColumnsWrapper,
  Step,
  Message,
  Img,
  H1,
  AdvicesWrapper,
  Advice,
  QrWrapper,
  Container,
} from "./styles";
import BankCardImg from "../../assets/imgs/card-e-size-1.1.png";
import Step3 from "../../assets/imgs/step3new.png";
import Card from "../../components/Card/Card";
import ExclamationMark from "../../assets/icons/ExclamationMark";
import { useTranslation } from "react-i18next";
import Privacy from "../../assets/icons/Privacy";
import AppContext from "../../utils/Context/context";
import { encodeQueryData, getQueryParams } from "../../helpers";

const DesktopModal = () => {
  const { t } = useTranslation();
  const { serverEndpoint } = useContext(AppContext);
  const root =
    serverEndpoint === "https://api.e-size.io/api/v1/"
      ? "https://web.e-size.io/"
      : "https://aiva-esize-web2.demo.gravity-group.ru/";

  const [scale, setScale] = useState(Math.min(window.innerWidth / 900, 1));

  useEffect(() => {
    const handleResize = () => {
    };

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  const queryParams = getQueryParams();
  console.log("queryParams:", queryParams);
  window.top.postMessage({id: 'esizeCode', code: queryParams.code, params: queryParams}, '*');

  const qrData = {
    url: `${root}?${encodeQueryData(queryParams)}`,
    format: "png",
  };
  console.log("qrData:", qrData);

  const queryString = encodeQueryData(qrData);
  console.log("queryString:", queryString);

  const qrSrc = `${serverEndpoint}qr?${queryString}`;
  console.log("qrSrc:", qrSrc);

  const innerWidth = window.innerWidth;
  const outerWidth = window.outerWidth;
  const innerHeight = window.innerHeight;
  const outerHeight = window.outerHeight;

  // const otladStr = `innerWidth: ${innerWidth}, outerWidth: ${outerWidth}, innerHeight: ${innerHeight}, outerHeight: ${outerHeight}`;

  console.log(innerWidth, outerWidth, innerHeight, outerHeight);

  return (
    <Page>
      <Container>
        <MainCard>
          <ColumnsWrapper>
            <FirstStep>
              <div>
                <Step>{t("scan1_title")}</Step>
                <Message>{t("shop_title1")}</Message>
              </div>
              <QrWrapper>
                <Img src={qrSrc} alt="QR-код" />
              </QrWrapper>
            </FirstStep>
            <SecondStep>
              <div>
                <Step>{t("scan2_title")}</Step>
                <Message>{t("shop_title2")}</Message>
                <Img src={BankCardImg} alt="" />
              </div>
              <Card
                alternative={true}
                text={t("scan1_warning")}
                icon={<ExclamationMark />}
              />
            </SecondStep>
            <ThirdStep>
              <div>
                <Step>{t("scan3_title")}</Step>
                <Message>{t("shop_title3")}</Message>
                <Img src={Step3} alt="" />
              </div>
              <Card
                alternative={true}
                text={t("scan1_safe")}
                icon={<Privacy />}
              />
            </ThirdStep>
          </ColumnsWrapper>
        </MainCard>
        <AdvicesWrapper>
          <H1>{t("shop_new_recommendations")}</H1>
          <ul>
            <li>
              <Advice>{t("shop_new_message1")}</Advice>
              {/*<Advice>{otladStr}</Advice>*/}
            </li>
            <li>
              <Advice>{t("shop_new_message2")}</Advice>
            </li>
            <li>
              <Advice>{t("shop_new_message3")}</Advice>
            </li>
          </ul>
        </AdvicesWrapper>
      </Container>
    </Page>
  );
};

export default DesktopModal;
