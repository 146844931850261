import React, { useContext, useEffect, useState } from 'react';
import Button from '../../components/Button/Button';
import AppContext from '../../utils/Context/context';
import Input from './Input/Input';
import GenderSwitch from './Switch/GenderSwitch';

const MockPage = () => {
  const [userid, setUserid] = useState('1000');
  const [shopid, setShopid] = useState('1');
  const [sex, setSex] = useState('male');
  const [salt, setSalt] = useState('Kq4HoULrcqYvilG3cgUFSQ');
  const [sign, setSign] = useState('');
  var md5 = require('md5');

  const { serverEndpoint } = useContext(AppContext);

  useEffect(() => {
    setSign(md5(userid + shopid + sex + salt));
  }, [userid, shopid, sex, salt, md5]);

  const clickHandler = () => {
    window.location.href = `${serverEndpoint}shop/getlink?userid=${userid}&shopid=${shopid}&sex=${sex}&sign=${sign}`;
  };

  return (
    <div
      style={{
        display: 'flex',
        justifyContent: 'center',
        flexDirection: 'column',
        flex: 1,
        minHeight: 600,
        padding: 10,
      }}
    >
      <h1 style={{
        margin: '60px 0', width: '100%', textAlign: 'center', color:'#4381E3', fontSize: 50}}>Shop</h1>
      <Input label='userid' value={userid} onChange={(e) => setUserid(e.target.value)} />
      <Input label='shopid' value={shopid} onChange={(e) => setShopid(e.target.value)} />
      <Input label='salt' value={salt} onChange={(e) => setSalt(e.target.value)} />
      <GenderSwitch setValue={setSex} value={sex} />
      <p style={{ opacity: 0.6, marginBottom: 60 }}>sign: {sign}</p>
      <Button onClick={clickHandler}>Подобрать размер</Button>
    </div>
  );
};

export default MockPage;
