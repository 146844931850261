import React from "react";
import Card from "../../../components/Card/Card";
import ExclamationMark from "../../../assets/icons/ExclamationMark";
import Privacy from "../../../assets/icons/Privacy";
import { Container, Content } from "./styles";
import PinnedButton from "../../../components/Button/PinnedButton";
import { useTranslation } from "react-i18next";
import Img from "../../../assets/imgs/card-e-size-1.1.png";

const FirstStep = ({ nextStep }) => {
  const { t } = useTranslation();

  const imgWidth = window.screen.width - 32;
  const imgHeight = (imgWidth * 7) / 11;

  return (
    <Container>
      <Content>
        <p>{t("scan1_message")}</p>
        <img width={imgWidth} height={imgHeight} src={Img} alt="Банковская карта" />
        <Card text={t("scan1_warning")} icon={<ExclamationMark />} />
        <Card text={t("scan1_safe")} icon={<Privacy />} />
      </Content>
      <PinnedButton onClick={nextStep}>{t("button_next")}</PinnedButton>
    </Container>
  );
};

export default FirstStep;
