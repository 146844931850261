import React from 'react';
import { Container, FirstRow, Half, Number, OutterRow, SecondRow, Unit } from './styles';
import { ReactComponent as LengthIcon } from '../../assets/icons/width.svg';
import { ReactComponent as WidthIcon } from '../../assets/icons/length.svg';
import { useTranslation } from 'react-i18next';

const CardSizes = ({ width = 99.9, length = 99.9, unit = 'см' }) => {
  const { t } = useTranslation();
  return (
    <Container>
      <OutterRow>
        <Half>
          <FirstRow>
            <WidthIcon />
            <p>{t('size_length')}</p>
          </FirstRow>
          <SecondRow>
            <Number>{length.toFixed(1)}</Number>
            <Unit>{unit}</Unit>
          </SecondRow>
        </Half>
        <Half>
          <FirstRow>
            <LengthIcon />
            <p>{t('size_width')}</p>
          </FirstRow>
          <SecondRow>
            <Number>{width.toFixed(1)}</Number>
            <Unit>{unit}</Unit>
          </SecondRow>
        </Half>
      </OutterRow>
    </Container>
  );
};

export default CardSizes;
