import React, { useRef, useState } from "react";
import { Camera } from "react-camera-pro";
import {
  CameraAndImgWrapper,
  Container,
  Content,
  HeaderStepContainer,
  HorizontalContainer,
} from "./styles";
import PinnedButton from "../../../components/Button/PinnedButton";
import LegTop from "../../../assets/imgs/LegTop.png";
import LegSide from "../../../assets/imgs/LegSide.png";
import { useTranslation } from "react-i18next";
import RoundButton from "../../../components/Button/RoundButton";
import { IconBtn } from "../../../components/StepHeader/styles";
import InfoIcon from "../../../assets/icons/Info";
import { gyroscopePermission } from "reactjs-gyroscope";

const SecondAndThirdStep = ({
  step,
  nextStep,
  saveFirstPhoto,
  saveSecondPhoto,
}) => {
  const { t } = useTranslation();
  const [state, setState] = useState(0);

  // 0 - instruction
  // 1 - top photo
  // 2 - instruction
  // 3 - side photo

  const pressHandler = async () => {
    if (state % 2 === 0) {
      //instructions
      setState(state + 1);
      gyroscopePermission().then();
    } else if (state === 1) {
      //top photo
      setState(state + 1);
      nextStep();
      const firstPhoto = camera.current.takePhoto();
      console.log("firstPhoto:", firstPhoto);
      saveFirstPhoto(firstPhoto);
    } else {
      //side photo
      const secondPhoto = camera.current.takePhoto();
      console.log("secondPhoto:", secondPhoto);
      saveSecondPhoto(secondPhoto);
      nextStep();
    }
  };

  const errorMessages = {
    noCameraAccessible: t("web_noCameraAccessible"),
    permissionDenied: t("web_permissionDenied"),
    switchCamera: t("switchCamera"),
    canvas: t("canvas"),
  };

  const handleInfoClick = () => {
    alert(t("dialog_personal_data_message"));
  };

  let orientationAngle =
    window.screen && window.screen.orientation
      ? window.screen.orientation.angle
      : window.orientation;
  if (orientationAngle === -90) {
    orientationAngle = 270;
  }

  const camera = useRef(null);
  return (
    <Container>
      <Content>
        {orientationAngle === 0 ? (
          <p>{state < 2 ? t("scan2_message") : t("scan3_message")}</p>
        ) : (
          <HeaderStepContainer>
            <h1 style={{ marginRight: 8 }}>{t(`scan${step}_title`)}</h1>
            <div style={{ display: "flex" }}>
              <h1 style={{ color: "#5D7380", fontWeight: 400 }}>
                {t("scan1_title_suffix")}
              </h1>
              <IconBtn onClick={handleInfoClick}>
                <InfoIcon />
              </IconBtn>
            </div>
          </HeaderStepContainer>
        )}
        <HorizontalContainer>
          {orientationAngle !== 0 && <RoundButton onClick={pressHandler} />}
          <CameraAndImgWrapper>
            {state === 0 && <img width="100%" src={LegTop} alt="Фото сверху" />}
            {state === 2 && <img width="100%" src={LegSide} alt="Фото сбоку" />}
            {state % 2 === 1 && (
              <Camera
                errorMessages={errorMessages}
                facingMode={"environment"}
                aspectRatio={1}
                ref={camera}
              />
            )}
          </CameraAndImgWrapper>
          {orientationAngle !== 0 && <RoundButton onClick={pressHandler} />}
        </HorizontalContainer>
      </Content>
      {orientationAngle === 0 && (
        <PinnedButton onClick={pressHandler}>
          {state % 2 === 0 ? t("button_next") : t("scan2_button_take_photo")}
        </PinnedButton>
      )}
    </Container>
  );
};

export default SecondAndThirdStep;
