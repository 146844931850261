import { useState } from "react";

function useAccelerometer() {
  // Acceleration
  const [Xa, setXa] = useState(0);
  const [Ya, setYa] = useState(0);
  const [Za, setZa] = useState(0);
  const [a, setA] = useState(0);

  // Acceleration + Gravity
  const [Xag, setXag] = useState(0);
  const [Yag, setYag] = useState(0);
  const [Zag, setZag] = useState(0);
  const [ag, setAg] = useState(0);

  // Gravity
  const [Xg, setXg] = useState(0);
  const [Yg, setYg] = useState(0);
  const [Zg, setZg] = useState(0);
  const [g, setG] = useState(0);

  // Rotate acceleration
  const [arAlpha, setArAlpha] = useState(0);
  const [arBeta, setArBeta] = useState(0);
  const [arGamma, setArGamma] = useState(0);

  const [ai, setAi] = useState(0);

  const [counterMotions, setCounterMotions] = useState(0);

  window.ondevicemotion = function (event) {
    if (
      Math.abs(Xag - event.accelerationIncludingGravity.x) > 0.25 ||
      Math.abs(Yag - event.accelerationIncludingGravity.y) > 0.25 ||
      Math.abs(Zag - event.accelerationIncludingGravity.z) > 0.25
    ) {
      setCounterMotions(counterMotions + 1);
      setXa(event.acceleration.x);
      setYa(event.acceleration.y);
      setZa(event.acceleration.z);
      setA(
        Math.sqrt(
          event.acceleration.x * event.acceleration.x +
            event.acceleration.y * event.acceleration.y +
            event.acceleration.z * event.acceleration.z
        )
      );

      setXag(event.accelerationIncludingGravity.x);
      setYag(event.accelerationIncludingGravity.y);
      setZag(event.accelerationIncludingGravity.z);
      setAg(
        Math.sqrt(
          event.accelerationIncludingGravity.x *
            event.accelerationIncludingGravity.x +
            event.accelerationIncludingGravity.y *
              event.accelerationIncludingGravity.y +
            event.accelerationIncludingGravity.z *
              event.accelerationIncludingGravity.z
        )
      );

      setXg(event.accelerationIncludingGravity.x - event.acceleration.x);
      setYg(event.accelerationIncludingGravity.y - event.acceleration.y);
      setZg(event.accelerationIncludingGravity.z - event.acceleration.z);
      setG(
        Math.sqrt(
          event.accelerationIncludingGravity.x *
            event.accelerationIncludingGravity.x +
            event.accelerationIncludingGravity.y *
              event.accelerationIncludingGravity.y +
            event.accelerationIncludingGravity.z *
              event.accelerationIncludingGravity.z
        ) -
          Math.sqrt(
            event.acceleration.x * event.acceleration.x +
              event.acceleration.y * event.acceleration.y +
              event.acceleration.z * event.acceleration.z
          )
      );

      setAi(Math.round(event.interval * 10000) / 10000);
      let rR = event.rotationRate;
      if (rR != null) {
        setArAlpha(Math.round(rR.alpha));
        setArBeta(Math.round(rR.beta));
        setArGamma(Math.round(rR.gamma));
      }
    }
  };

  return {
    Xag,
    Yag,
    Zag,
    ag,
    Xg,
    Yg,
    Zg,
    g,
    Xa,
    Ya,
    Za,
    a,
    arAlpha,
    arBeta,
    arGamma,
    ai,
    counterMotions,
  };
}

export default useAccelerometer;
