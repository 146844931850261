import styled from 'styled-components';
import img from '../../assets/imgs/ErrorBG.png';

export const Container = styled.div`
  background-image: url(${img});
  background-size: cover;

  display: flex;
  flex-direction: column;
  justify-content: center;

  min-height: 100vh;
  align-items: center;
`;

export const Alert = styled.div`
  background-color: #d6d6d6;
  border-radius: 14px;
  width: 270px;
`;

export const TextBlock = styled.div`
  padding: 16px;
`;

export const Title = styled.h1`
  font-size: 17px;
  line-height: 22px;
  font-weight: 600;
  text-align: center;
  margin-bottom: 2px;
`;
export const Message = styled.p`
  font-size: 13px;
  line-height: 17px;
  font-weight: 400;
  text-align: center;
`;



export const AlertBtn = styled.button`
  height: 44px;
  background-color: rgba(0, 0, 0, 0);
  border-width: 0;
  border-top-width: 0.5px;
  border-color: #9F9D9E;

  width: 100%;

  color: #2683ea;
  font-size: 17px;
  font-weight: 600;
  line-height: 22px;
`;
