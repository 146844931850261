import styled from "styled-components";

export const Container = styled.div`
  background-color: white;
  padding: 16px 16px 0px;
  border-radius: 16px;
`

export const OutterRow = styled.div`
  display: flex;
`

export const Half = styled.div`
width: calc(50vw)
`

export const FirstRow = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;
`

export const SecondRow = styled.div`
  display: flex;
  align-items: flex-end;
  gap: 2px;
`
export const Number = styled.p`
  font-size: 45px;
  line-height: 52px;
`
export const Unit = styled.p`
  font-size: 22px;
  line-height: 28px;
  margin-bottom: 4px;
  color: #5D7380;
`