import * as React from 'react';

const InfoIcon = () => (
  <svg width={48} height={48} fill='none' xmlns='http://www.w3.org/2000/svg'>
    <g clipPath='url(#a)'>
      <path
        d='M23 19h2v2h-2v-2Zm0 4h2v6h-2v-6Zm1-9c-5.52 0-10 4.48-10 10s4.48 10 10 10 10-4.48 10-10-4.48-10-10-10Zm0 18c-4.41 0-8-3.59-8-8s3.59-8 8-8 8 3.59 8 8-3.59 8-8 8Z'
        fill='#2683EA'
      />
    </g>
    <defs>
      <clipPath id='a'>
        <rect x={4} y={4} width={40} height={40} rx={20} fill='#fff' />
      </clipPath>
    </defs>
  </svg>
);

export default InfoIcon;
