import React from 'react';
import { useTranslation } from 'react-i18next';
import OkayIcon from '../../assets/icons/ok';
import { ButtonReady, Container, Message } from './styles';
import { useNavigate } from 'react-router-dom';

const Final = () => {
  const { t } = useTranslation();
  const navigate = useNavigate()

  const clickHandler = () => {
    navigate('/scanning')
  }
  return (
    <Container>
      <ButtonReady onClick={clickHandler}>Готово</ButtonReady>
      <OkayIcon />
      <Message>{t('size_sent_to_shop_title')}</Message>
    </Container>
  );
};

export default Final;
