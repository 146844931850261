import * as React from "react"

const Ok = () => (
  <svg width={48} height={48} fill="none" xmlns="http://www.w3.org/2000/svg">
    <g clipPath="url(#a)">
      <path
        d="M23.877 47.563c3.19 0 6.194-.613 9.015-1.84a23.927 23.927 0 0 0 7.451-5.06 23.796 23.796 0 0 0 5.048-7.44c1.218-2.812 1.827-5.82 1.827-9.026 0-3.189-.613-6.194-1.839-9.014a24.076 24.076 0 0 0-5.06-7.464 23.491 23.491 0 0 0-7.452-5.048C30.047 1.46 27.043.856 23.854.856c-3.189 0-6.19.605-9.002 1.815a23.672 23.672 0 0 0-7.452 5.048 23.802 23.802 0 0 0-5.06 7.464C1.12 18.003.512 21.008.512 24.197c0 3.205.613 6.214 1.839 9.027a23.97 23.97 0 0 0 5.06 7.44 23.968 23.968 0 0 0 7.44 5.06c2.812 1.226 5.821 1.839 9.026 1.839Zm0-4.28c-2.644 0-5.12-.492-7.428-1.477a19.155 19.155 0 0 1-6.082-4.1 18.99 18.99 0 0 1-4.086-6.08c-.978-2.309-1.466-4.785-1.466-7.429s.488-5.12 1.466-7.428a18.99 18.99 0 0 1 4.087-6.081 19.025 19.025 0 0 1 6.07-4.087c2.299-.977 4.77-1.466 7.415-1.466 2.644 0 5.12.489 7.428 1.466a18.99 18.99 0 0 1 6.082 4.087 19.153 19.153 0 0 1 4.098 6.081c.986 2.308 1.479 4.784 1.479 7.428.016 2.645-.47 5.12-1.455 7.428a19.254 19.254 0 0 1-4.086 6.082 18.977 18.977 0 0 1-6.082 4.099c-2.316.985-4.796 1.478-7.44 1.478Zm-2.5-8.268c.817 0 1.458-.377 1.923-1.13L33.59 17.779c.144-.224.272-.465.384-.721.112-.256.169-.521.169-.793 0-.53-.2-.954-.601-1.274a2.1 2.1 0 0 0-1.347-.481c-.673 0-1.242.36-1.706 1.082l-9.183 14.735-4.351-5.577c-.272-.352-.545-.593-.817-.721a2.119 2.119 0 0 0-.914-.192c-.529 0-.973.184-1.334.553-.36.368-.54.817-.54 1.346 0 .513.184 1.01.552 1.49l5.457 6.659c.304.4.617.689.937.865.32.176.681.265 1.082.265Z"
        fill="#2683EA"
      />
    </g>
    <defs>
      <clipPath id="a">
        <path fill="#fff" d="M0 0h48v48H0z" />
      </clipPath>k
    </defs>
  </svg>
)

export default Ok
