import React from 'react'
import { ButtonWrapper } from './styles'


const Button = ({children,  ...props}) => {
  return (
    <ButtonWrapper {...props} >
        {children}
    </ButtonWrapper>
  )
}

export default Button