import styled from 'styled-components';
import img from '../../assets/imgs/1.jpg';

export const Container = styled.div`
  background-image: url(${img});
  background-size: cover;

  display: flex;
  flex-direction: column;
  justify-content: space-between;

  padding: 16px;
  padding-bottom: 80px;
  min-height: 100vh;
  align-items: center;
`;

export const Title = styled.h1`
  color: white;
  text-align: center;
`;

export const ContentWrapper = styled.div`
  display: flex;
  width: 100%;
  flex-direction: column;
  gap: 16px;
`;

export const IconBtn = styled.button`
  background-color: rgba(0, 0, 0, 0);
  align-self: flex-end;
  height: 64px;
  width: 64px;
  border: none;
  cursor: pointer;
`;
