import React from 'react'
import Button from './Button'
import { PinBtnWrapper } from './styles'

const PinnedButton = ({children, ...props}) => {
  return (
    <PinBtnWrapper>
      <Button {...props}>
        {children}
      </Button>
    </PinBtnWrapper>
  )
}

export default PinnedButton