import styled from "styled-components";
import { PageContainer } from "../../../styles/styles";

export const Container = styled(PageContainer)`
  align-items: center;
  justify-content: start;
  gap: 8px;
  padding: 72px 0 0 0;

`
export const Title = styled.h1`
  text-align: center;
`