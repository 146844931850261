import axios from "axios";
import React, { useCallback, useEffect, useState } from "react";
import { useContext } from "react";
import { useNavigate } from "react-router-dom";
import StepHeader from "../../components/StepHeader/StepHeader";
import AppContext from "../../utils/Context/context";
import FirstStep from "./FirstStep/FirstStep";
import SecondAndThirdStep from "./SecondAndThirdStep/SecondAndThirdStep";
import { Container } from "./styles";
import { makeBlob, rotateCallback } from "../../utils/functions/base64";
import useAccelerometer from "../../utils/hooks/useAccelerometer";
import { t } from "i18next";
import { accelerometerDataToAngle } from "../../utils/functions/accelerometerHelpers";
import Loading from "./Loading/Loading";

const Scanning = () => {
  const navigate = useNavigate();
  const { guestToken, setResults, esizeid, serverEndpoint } =
    useContext(AppContext);

  const cancellation = () => {
    setStep(1);
    setIsScanning(false);
    setPhotos64base({ legTop: "", legSide: "" });
  };

  const [step, setStep] = useState(1);
  const [isScanning, setIsScanning] = useState(false);
  const [isReadyToPost, setIsReadyToPost] = useState(false);
  const [photos64base, setPhotos64base] = useState({
    legTop: "data ",
    legSide: "data ",
  });

  //ориетация в пространстве
  const [orientationAngle, setOrientationAngle] = useState(0);
  const { Xg, Yg, g } = useAccelerometer();

  const platform = window.navigator.platform;
  const isReversedAcc =
    platform === "Android" ||
    platform === "Linux aarch64" ||
    platform === "Linux armv81";

  const nextStep = () => {
    if (step === 3) {
      setTimeout(() => setIsReadyToPost(true), 1000);
      setIsScanning(true);
      return;
    }
    setStep(step + 1);
  };

  const postHandler = useCallback(async () => {
    let form = new FormData();
    form.append("img1", makeBlob(photos64base.legTop));
    form.append("img2", makeBlob(photos64base.legSide));
    form.append("esizeid", esizeid);

    const options = {
      method: "post",
      url: `${serverEndpoint}calculate`,
      headers: {
        authorization: "Bearer " + guestToken,
        "Content-Type": "multipart/form-data",
      },
      data: form,
    };

    console.log(options);
    await axios(options)
      .then((response) => {
        console.log("response:", response.data);
        if (response.data.code === 0) {
          setResults({
            length: response.data.data.length,
            width: response.data.data.width,
          });
          navigate("/results");
        } else if (response.data.code === 1) {
          navigate("/error1");
        } else {
          navigate("/error2");
        }
      })
      .catch((error) => {
        console.log("backend error:", error);
      });
  }, [photos64base, esizeid, guestToken, setResults, navigate, serverEndpoint]); // зависимости функции

  useEffect(() => {
    if (isReadyToPost) postHandler();
  }, [isReadyToPost, postHandler]);

  //попап для гавнофонов
  useEffect(() => {
    if (g === 0 && isReversedAcc && step === 3) {
      setTimeout(() => {
        alert(t("screen_orientation_lock_alert"));
      }, 1000);
    }
  }, [g, isReversedAcc, step]);

  //установка отслеживания ориентации
  useEffect(() => {
    const updateOrientation = () => {
      // Определяем функцию для обновления угла с задержкой
      setTimeout(() => {
        let newOrientationAngle =
          window.screen && window.screen.orientation
            ? window.screen.orientation.angle
            : window.orientation;
        if (newOrientationAngle === -90) {
          newOrientationAngle = 270;
        }
        setOrientationAngle(newOrientationAngle);
      }, 150); // Задержка в 150 миллисекунд для учета задержки возможно связанной с акселерометром
    };

    window.addEventListener("orientationchange", updateOrientation);

    // Вызываем обработчик сразу для инициализации
    updateOrientation();

    return () => {
      window.removeEventListener("orientationchange", updateOrientation);
    };
  }, []);

  //сохранение фоток-vvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvv
  const savePhoto = useCallback(
    (photo64base, metadataKey) => {
      const degrees = accelerometerDataToAngle(
        Yg,
        Xg,
        isReversedAcc,
        orientationAngle
      );
      rotateCallback(photo64base, degrees, (result) => {
        setPhotos64base((prevPhotos) => ({
          ...prevPhotos,
          [metadataKey]: result,
        }));
      });
    },
    [Xg, Yg, isReversedAcc, orientationAngle]
  );

  const saveFirstPhoto = useCallback(
    (photo64base) => {
      savePhoto(photo64base, "legTop");
    },
    [savePhoto]
  );

  const saveSecondPhoto = useCallback(
    (photo64base) => {
      savePhoto(photo64base, "legSide");
    },
    [savePhoto]
  );
  //^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^

  return (
    <Container>
      {!isScanning && orientationAngle === 0 && <StepHeader step={step} />}
      {!isScanning ? (
        step === 1 ? (
          <FirstStep nextStep={nextStep} />
        ) : (
          <SecondAndThirdStep
            step={step}
            nextStep={nextStep}
            saveFirstPhoto={saveFirstPhoto}
            saveSecondPhoto={saveSecondPhoto}
          />
        )
      ) : (
        <Loading cancellation={cancellation} />
      )}
    </Container>
  );
};

export default Scanning;
