import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  padding: 16px 24px;
  background-color: #f2f2f7;
  border-radius: 16px;
  gap: 16px;
`;

export const IconWrapper = styled.div`
  width:40px;
  height: 40px;
`

export const Title = styled.p`
  align-self: center;
`
