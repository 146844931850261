import styled from "styled-components";

export const Container = styled.div`
  padding: 0 16px;
  display: flex;
  flex-direction: column;
  min-height: calc(100vh - 100px);
  justify-content: center;
  align-items: center;
`

export const Message = styled.p`
margin-top: 12px;
  text-align: center;
  font-weight: 400;
  font-size: 17px;
  line-height: 28px;
  color: #2683EA;
`

export const ButtonReady = styled.p`
  font-weight: 400;
  font-size: 17px;
  line-height: 22px;
  color: #2683EA;
  position: absolute;
  top: 30px;
  right: 30px;
`