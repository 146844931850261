import React from 'react';
import PropTypes from 'prop-types';
import { InputWrapper, Label, StyledInput } from './styles';

const Input = ({ label, ...rest }) => (
  <InputWrapper>
    <Label>{label}</Label>
    <StyledInput {...rest} />
  </InputWrapper>
);

Input.propTypes = {
  label: PropTypes.string.isRequired,
};

export default Input;
